import {toast} from 'react-toastify'

export default function setupAxios(axios: any, store: any) {
    axios.interceptors.request.use(
        (config: any) => {
            config.baseURL = process.env.REACT_APP_API_URL
            const {
                auth: {access_token, food_code},
            } = store.getState()
            if (access_token) {
                config.headers.Authorization = `Bearer ${access_token}`
                config.headers['Food-Code'] = food_code
                config.headers['Is-Platform'] = true
            }
            return config
        },
        (err: any) => {
            return Promise.reject(err)
        }
    )
    axios.interceptors.response.use(
        (config: any) => config,
        (err: any) => {
            if (err.response.data.message) {
                toast.error(err.response.data.message)
            } else if (err.response.message) {
                toast.error(err.response.message)
            } else {
                toast.error(err.request.statusText)
            }
            return Promise.reject(err)
        }
    )
}
