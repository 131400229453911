import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {AuditRoutes} from './internal/AuditRoutes'

import {DashboardRoutes} from './internal/DashboardRoutes'
import {ExportRoutes} from './internal/ExportRoutes'
import {ReportRoutes} from './internal/ReportRoutes'
import {SettingsRoutes} from './internal/SettingsRoutes'
import {AttractiveRoutes} from './internal/AttractiveRoutes'
import {SecurityRoutes} from './internal/SecurityRoutes'
import {RegistrationsRoutes} from './internal/RegistrationsRoutes'

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/perfil' component={AccountPage} />

                {/*Rotas para Conta*/}
                <Route path='/analises' component={DashboardRoutes} />

                {/*Rotas para Conta*/}
                <Route path='/cadastros' component={RegistrationsRoutes} />

                {/*Rotas para Conta*/}
                <Route path='/seguranca' component={SecurityRoutes} />

                {/*Rotas para Conta*/}
                <Route path='/relatorios' component={ReportRoutes} />

                {/*Rotas para Conta*/}
                <Route path='/exportacoes' component={ExportRoutes} />

                {/*Rotas para Conta*/}
                <Route path='/auditoria' component={AuditRoutes} />

                {/*Rotas para Configurações*/}
                <Route path='/configuracoes' component={SettingsRoutes} />

                {/*Rotas para Fluxo Financeiro*/}
                <Route path='/atrativos' component={AttractiveRoutes} />

                <Redirect from='/auth' to='/analises/financeiro' />
                <Redirect exact from='/' to='/analises/financeiro' />
                <Redirect to='error/404' />
            </Switch>
        </Suspense>
    )
}
