import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function SettingsRoutes() {
    /*Rotas para Produtos*/
    const DatatableProducts = lazy(
        () => import('../../pages/registrations/product/DatatableProduct')
    )

    /*Rotas para Formas de Pagamento*/
    const DatatablePurchaseType = lazy(
        () => import('../../pages/settings/purchase_type/DatatablePurchaseType')
    )

    return (
        <Switch>
            {/*Rotas para Produtos*/}
            <Route path='/configuracoes/produtos' component={DatatableProducts} />

            {/*Rotas para Formas de Pagamento*/}
            <Route path='/configuracoes/formas-de-pagamento' component={DatatablePurchaseType} />
        </Switch>
    )
}
