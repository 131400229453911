import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function SecurityRoutes() {
    /*Rotas para Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/security/access/user/DatatableUser'))

    /*Rotas para Perfis*/
    const DatatablePrivileges = lazy(
        () => import('../../pages/security/access/privilege/DatatablePrivilege')
    )
    const StorePrivilege = lazy(
        () => import('../../pages/security/access/privilege/StorePrivilege')
    )
    const UpdatePrivilege = lazy(
        () => import('../../pages/security/access/privilege/UpdatePrivilege')
    )

    return (
        <Switch>
            {/*Rotas para Usuários*/}
            <Route path='/seguranca/acessos/usuarios' component={DatatableUsers} />

            {/*Rotas para Perfis*/}
            <Route path='/seguranca/acessos/perfis/:id/atualizar' component={UpdatePrivilege} />
            <Route path='/seguranca/acessos/perfis/cadastrar' component={StorePrivilege} />
            <Route path='/seguranca/acessos/perfis' component={DatatablePrivileges} />
        </Switch>
    )
}
