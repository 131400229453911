import {Formik} from 'formik'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {TextInput, TextInputMask} from '../../../widgets/form'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import {codEventMask} from '../../../masks'
import {TextInputPassword} from '../../../widgets/form/TextInputPassword'

const validationSchema = Yup.object().shape({
    food_code: Yup.string().required('Estabelecimento é obrigatório'),
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caractéres')
        .max(50, 'Máximo de 50 caractéres')
        .required('E-mail é obrigatório'),
    password: Yup.string()
        .min(3, 'Mínimo 3 caractéres')
        .max(50, 'Máximo 50 caractéres')
        .required('Senha é obrigatório'),
})

const initialValues = {
    email: '',
    password: '',
    food_code: '',
}

export function Login() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [inputType, setInputType] = useState('password')
    const [eye, setEye] = useState('bi bi-eye-slash-fill')

    function toggleEye(e: EventTarget, eye: string) {
        if (eye === 'bi bi-eye-fill') {
            setEye('bi bi-eye-slash-fill')
            setInputType('password')
            return
        } else if (eye === 'bi bi-eye-slash-fill') {
            setEye('bi bi-eye-fill')
            setInputType('text')
            return
        }
    }

    async function handleSubmit(values: typeof initialValues) {
        try {
            setLoading(true)
            const {data} = await login({
                email: values.email,
                password: values.password,
                food_code: values.food_code,
            })
            dispatch(
                auth.actions.login(
                    data.data.tokens.access_token,
                    data.data.tokens.expires_at,
                    values.food_code,
                    data.data.food.nm_food
                )
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <form className='form w-100' onSubmit={props.handleSubmit} noValidate>
                    <div className='mb-5 bg-light p-5 rounded text-center'>
                        <span className='text-dark h3 text-justify'>
                            Bem-vindo(a) a Kachin!
                            <br />
                            Gestão completa para seu estabelecimento.
                        </span>
                    </div>

                    <div className='fv-row mb-5'>
                        <TextInputMask
                            name='food_code'
                            formik={props}
                            label='Cód. do Estabelecimento'
                            placeholder='Ex: 000000'
                            mask={codEventMask}
                        />
                    </div>
                    <div className='fv-row mb-5'>
                        <TextInput
                            name='email'
                            formik={props}
                            label='E-mail'
                            type='email'
                            placeholder='Ex: contato@kachin.com.br'
                        />
                    </div>
                    <div className='fv-row mb-5'>
                        <TextInputPassword
                            name='password'
                            placeholder='Entre com a senha'
                            label='Senha'
                            type={inputType}
                            eye={eye}
                            toggleEye={toggleEye}
                        />
                    </div>
                    <div className='text-center'>
                        <ButtonAuthSubmit loading={loading} buttonTitle='Acessar plataforma' />
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-secondary w-100'
                            onClick={() =>
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Esqueceu a senha?',
                                    html: 'Dirija-se à administração do evento e solicite um administrador para realizar a alteração de senha!',
                                    confirmButtonText: 'Recebido!',
                                    confirmButtonColor: '#EC375E',
                                    allowOutsideClick: false,
                                })
                            }
                        >
                            Esqueceu a senha?
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}
