import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function DashboardRoutes() {

    /*Rotas para Historico de Atividades*/
    const DashboardFinancial = lazy(() => import('../../pages/dashboard/DashboardFinancial'))
    const DashboardAdministrative = lazy(() => import('../../pages/dashboard/DashboardAdministrative'))
    const DashboardBI = lazy(() => import('../../pages/dashboard/DashboardBI'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/analises/financeiro' component={DashboardFinancial} />
            <Route path='/analises/administrativo' component={DashboardAdministrative} />
            <Route path='/analises/bi' component={DashboardBI} />

        </Switch>
    )

}
