import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import { UserModel } from '../../../models'

import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    UserRequested: '[Request User] Action',
    UserLoaded: '[Load User] Auth API',
    SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
    user: undefined,
    access_token: undefined,
    expires_at: undefined,
    food_code: undefined,
    nm_food: undefined
}

export interface IAuthState {
    user?: UserModel
    access_token?: string
    expires_at?: string
    food_code?: string
    nm_food?: string
}

export const reducer = persistReducer(
    {
        storage,
        key: 'v100-demo1-auth',
        whitelist: ['user', 'access_token', 'expires_at', 'privilege', 'food_code', 'nm_food'],
    },
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
        switch (action.type) {
            case actionTypes.Login: {
                const access_token = action.payload?.access_token
                const expires_at = action.payload?.expires_at
                const food_code = action.payload?.food_code
                const nm_food = action.payload?.nm_food
                return {
                    access_token,
                    expires_at,
                    user: undefined,
                    privilege: undefined,
                    food_code,
                    nm_food,
                }
            }

            case actionTypes.Logout: {
                return initialAuthState
            }

            case actionTypes.UserRequested: {
                return {...state, user: undefined, privilege: undefined}
            }

            case actionTypes.UserLoaded: {
                const user = action.payload?.user
                return {...state, user}
            }

            case actionTypes.SetUser: {
                const user = action.payload?.user
                return {...state, user}
            }

            default:
                return state
        }
    }
)

export const actions = {
    login: (access_token: string, expires_at: Date, food_code: string, nm_food: string) => ({
        type: actionTypes.Login,
        payload: {access_token, expires_at, food_code, nm_food},
    }),
    logout: () => ({type: actionTypes.Logout}),
    requestUser: () => ({
        type: actionTypes.UserRequested,
    }),
    fulfillUser: (user: UserModel) => ({
        type: actionTypes.UserLoaded,
        payload: {user},
    }),
    setUser: (user?: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser())
    })

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const {data: user} = yield getUserByToken()
        yield put(actions.fulfillUser(user.data.user))
    })
}
