import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function RegistrationsRoutes() {
    /*Rotas para Cartões*/
    const DatatableCards = lazy(() => import('../../pages/registrations/card/DatatableCard'))
    const ShowCard = lazy(() => import('../../pages/registrations/card/ShowCard'))

    /*Rotas para Categorias*/
    const DatatableCategories = lazy(
        () => import('../../pages/registrations/categories/DatatableCategories')
    )

    /*Rotas para Produtos*/
    const DatatableProducts = lazy(
        () => import('../../pages/registrations/product/DatatableProduct')
    )

    /*Rotas para Receitas*/
    const DatatableRevenueType = lazy(
        () => import('../../pages/registrations/revenue/DatatableRevenueType')
    )
    const DatatableRevenue = lazy(
        () => import('../../pages/registrations/revenue/DatatableRevenue')
    )

    /*Rotas para Custos*/
    const DatatableCostType = lazy(
        () => import('../../pages/registrations/costs/DatatableCostType')
    )
    const DatatableCost = lazy(() => import('../../pages/registrations/costs/DatatableCost'))

    return (
        <Switch>
            {/*Rotas para Cartões*/}
            <Route path='/cadastros/cartoes/:id' component={ShowCard} />
            <Route path='/cadastros/cartoes' component={DatatableCards} />

            {/*Rotas para Categorias*/}
            <Route path='/cadastros/categorias' component={DatatableCategories} />

            {/*Rotas para Produtos*/}
            <Route path='/cadastros/produtos' component={DatatableProducts} />

            {/*Rotas para Receitas*/}
            <Route path='/cadastros/receitas/categorias' component={DatatableRevenueType} />
            <Route path='/cadastros/receitas/lancamentos' component={DatatableRevenue} />

            {/*Rotas para Custos*/}
            <Route path='/cadastros/custos/categorias' component={DatatableCostType} />
            <Route path='/cadastros/custos/lancamentos' component={DatatableCost} />
        </Switch>
    )
}
