import {FormikProps} from 'formik'
import React from 'react'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    touched?: boolean
    error?: string
    name: string
    label?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
    required?: boolean
    disabled?: boolean
    placeholder?: string
    formik?: FormikProps<any>
    children: React.ReactNode
    useSelect?: boolean
}
export function Select({
    touched,
    error,
    name,
    label,
    value = '',
    onChange,
    onBlur,
    required = false,
    children,
    placeholder,
    formik,
    disabled = false,
    useSelect = true,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <select
                className='form-select form-select-solid'
                id={name}
                name={name}
                value={value || formik?.values[name]}
                onChange={onChange || formik?.handleChange}
                onBlur={formik ? formik.handleBlur : onBlur}
                {...props}
            >
                {useSelect && <option value={''}>{placeholder || 'Selecione...'}</option>}
                {children}
            </select>
            {(formik?.touched[name] && formik?.errors[name]) || error ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik?.errors[name] || error}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    )
}
