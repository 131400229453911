import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function AttractiveRoutes() {
    /*Rotas para Promoções*/
    const DatatableSale = lazy(() => import('../../pages/attractive/sale/DatatableSale'))
    const ShowSale = lazy(() => import('../../pages/attractive/sale/ShowSale'))

    return (
        <Switch>
            {/*Rotas para Promoções*/}
            <Route path='/atrativos/promocoes/:id/visualizar' component={ShowSale} />
            <Route path='/atrativos/promocoes' component={DatatableSale} />
        </Switch>
    )
}
